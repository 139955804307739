.menu {
    display: block;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 10px 10px 10px 10px;
            color: #fff;
            display: block;

            a,
            span {
                cursor: pointer;
                display: block;
                width: 100%;
                height: 40px;
                color: #fff;
                text-align: center;
                line-height: 50px;

                &:hover {
                    color: #bbbbbb;
                }

                &.is-active {
                    background-color: #fecb00;
                    color: #000;
                    border-radius: 5px;
                }
            }
        }
    }
}

.incoming-messages-tab {
    position: relative;

    > div {
        position: absolute;
        top: -4px;
        right: 4px;
        background-color: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        animation: bump 0.3s ease-out;
    }
}

@keyframes bump {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(5px); /* Move down */
    }
    60% {
        transform: translateY(-5px); /* Move up */
    }
    100% {
        transform: translateY(0); /* Back to original position */
    }
}
