.app {
    height: 100vh;
    flex-flow: column;
    display: flex;

    .top-bar {
        flex: 0 auto;
        color: #fff;
        min-height: 30px;
        background-color: #000;
        line-height: 30px;
        text-align: right;
        padding-right: 20px;

        b {
            margin-right: 10px;
        }

        > span,
        > label {
            cursor: pointer;
        }
    }

    .top-bar-select {
        display: inline-block;
        margin-left: 15px;
    }

    .content-area {
        display: flex;
        flex-grow: 4;
        flex: auto;
        flex-flow: row;
    }

    .menu {
        flex: 0 0 60px;
        order: 2;
        background-color: rgb(38, 38, 38);
        color: #fff;
        overflow: hidden;
    }

    .content {
        flex: 1 auto;
        order: 3;
        text-align: left;
        position: relative;
        padding: 14px;
        overflow: auto;

        .style-bar {
            width: 100%;
            background-color: rgb(255, 203, 5);
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            z-index: -100;
        }

        .box {
            border-radius: 5px;
            background-color: #fff;
            min-height: 200px;
            border-color: rgba(0, 0, 0, 0.1);
            border-width: 1px;
            border-style: solid;
        }

        .box,
        .header,
        footer {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.environment-banner {
    position: absolute;
    bottom: 10px;
    left: 0;
    font-size: 14px;
    background: rgb(255, 203, 5);
    font-weight: bold;
    padding: 10px 15px;
    text-transform: uppercase;
}

footer {
    margin-top: 7px;
    font-size: 10px;
    color: #969696;
    margin-bottom: 70px; // Some extra space for the floating action button
}

a {
    &:hover,
    &:active,
    &:visited,
    &:link {
        color: #000;
    }
}

//MuiInputBase-root MuiInput-root jss38 MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl MuiInputBase-multiline MuiInput-multiline

.MuiInput-formControl {
    min-height: 33px;
}
