
.user-table td {
    font-size: 1rem;
}

.assigned-stores {
    > div {
        margin: 4px 4px 2px 0;
    }
}
