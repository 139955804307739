
.loader-container {
    position: relative;
}

.button-wrapper {
    margin-top: 10px;
    > * {
        margin: 10px 10px 0 0 !important;
        display: inline-block;
    }
}

.button-wrapper-right {
    margin-top: 10px;
    text-align: right;
    > * {
        margin: 10px 0 0 10px !important;
        display: inline-block;
    }
}

.button-wrapper-no-margin {
    margin-top: -10px;
}

/* Chrome, Safari, Edge, Opera */
.number-input-hide-arrows input::-webkit-outer-spin-button,
.number-input-hide-arrows input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-input-hide-arrows input[type=number] {
    -moz-appearance: textfield;
}

.input-fontsize-inherit .MuiInputBase-root,
.input-fontsize-inherit .MuiTypography-body1,
.input-fontsize-inherit input {
    font-size: inherit;
}


.rdt_TableCell {
    font-size: 0.875rem; // same as material ui
}

.button-red.MuiButton-outlinedPrimary {
    color: #f44336;
    border-color: currentColor !important;
    &:hover {
        color: darken(#f44336, 15%);
    }
}

.react-datepicker-wrapper {
    display: block !important;
}

input#bookBeforeTime:invalid + fieldset {
    border-color: #f44336 !important;
}

#deviatingDatesForm {
    input#date:invalid + fieldset {
        border-color: #f44336 !important;
    }

    input#timeAvailable:invalid + fieldset {
        border-color: #f44336 !important;
    }
}

.MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
        border-color: #262626;
        border-width: 1px;
    }

    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-width: 2px !important;
        }
    }

    &.Mui-disabled {
        .MuiOutlinedInput-notchedOutline {
            border-color: #00000022 !important;
            border-width: 1px !important;
        }
    }

    &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
            color: #f44336 !important;
            border-color: #f44336 !important;
        }
    }
}

.customFieldError {
    > label {
        color: #f44336;
    }

    > div > fieldset {
        border-color: #f44336 !important;
    }
}