.service-order-form {
    h3 {
        font-size: 1.25rem;
        font-weight: normal;
        margin: 0;
        float: right;
    }
}

.service-order-sms-tab {
    position: relative;
    width: 480px;
    margin: 0 auto;
}

.sms-send-message {
    margin-top: 30px;

    .MuiInputBase-root {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.sms-not-allowed {
    text-align: center;
    margin: 30px 0;
}

.sms-message-body {
    display: block;
    font-size: 1rem;
    font-weight: 400;
}

li.sms-message-bubble {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    position: relative;
    max-width: 60%;
    overflow-wrap: anywhere;

    &.right {
        background-color: #dcf4ff;
        right: -15%;
        border-radius: 10px 10px 10px 0;
    }

    &.left {
        background-color: #f6f6f6;
        left: -15%;
        border-radius: 10px 10px 0 10px;
    }
}
