@import-normalize; /* bring in normalize.css styles */

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(223, 223, 223);
}

body, html {
    font-size: 14px !important;
}

@import './globalStyles/util.scss';
@import './globalStyles/card.scss';
@import './globalStyles/grid.scss';
@import './globalStyles/tables.scss';
