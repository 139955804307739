.dashboard-tabs-wrapper {
    display: flex;
    padding: 12px 0;

    .MuiTabs-root {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .MuiTab-root {
        min-width: 0 !important;
        width: 120px;
    }

    .tab {
        padding: 5px 0;
        font-size: 11px;
        line-height: 1.3;
    }
    .tab b {
        font-size: 18px;
        display: block;
    }

    > div {
        min-width: 120px;
    }

    > div + div {
        flex-grow: 1;
        padding: 0 12px;
    }
}
