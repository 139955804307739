.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
    // Fix problem with material input rendered wrong
    display: block !important;
    position: absolute;
    visibility: hidden;
    left: -99999px;
    top: -99999px;
}

body.modal-open,
body.modal-open3{
    overflow: hidden;
}

.dialog2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease-in;
    z-index: 100;
    overflow-y: scroll;
}

.dialog2-tabs-wrapper {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 10px 100px; // extra padding bottom for action button
    min-width: 768px;
    width: 100%;
}

.dialog2-close-button {
    display: flex;
    justify-content: center;
    height: 36px;
    width: 36px;
    padding: 0 1rem 0 1rem;
    border: none;
    font-size: 20px;
    line-height: 1.8;
    color: #fff;
    background: #000;
    cursor: pointer;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 0;
    right: 10px;

    &:hover {
        background: #333;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 5px rgba(130, 138, 145, 0.5);
    }
}

.dialog2-tabs {
    position: relative;
}

.dialog2-tab-list {
    display: flex;
    margin: 0;
    padding: 0;
    min-height: 36px;
}

.dialog2-tab {
    background: #000;
    border-color: #aaa;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 0 !important;
    padding-bottom: 0;
    margin-left: 20px;
    height: 36px;
    width: 166px;
    cursor: pointer;
    z-index: 1;
}

.dialog2-selected-tab {
    background: #fff;
    border-color: #aaa;
    color: black;
    outline: none !important;
    cursor: inherit;
}

.dialog2-panel-wrapper {
    background: #fff;
    background-clip: padding-box;
    flex-grow: 1;
    padding: 24px;
    transition: transform 0.3s ease-out;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    max-width: 98vw;
    overflow-x: auto;
    position: relative; // for loaders
}

.dialog2.dialog2-fullscreen {
    .dialog2-tabs-wrapper {
        max-width: 100%;
    }
}
