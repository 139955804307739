.code-preview {
    white-space: pre-wrap;
    font-size: 12px;
    background: rgba(0, 60, 140, .03);
    border: 1px solid rgba(0, 60, 140, .1);
    padding: 4px;
}

.log-status {
    display: inline-block;
    padding: 2px 6px;
    color: #fff;
    font-weight: bold;
    &.log-status--pending {
        background: #797979;
    }
    &.log-status--success {
        background: #4caf50;
    }
    &.log-status--failed {
        background: #f44336;
    }
}
