.store-administration-page {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    max-width: 1200px;

    form>div {
        max-width: 100%;
    }

    table {
        margin: 20px;
    }
}

.store-metadata-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 50px;
    flex-grow: 1;
}

.store-metadata-form {
    flex-grow: 1;

    p {
        min-width: 100%;
        max-width: min-content;
    }

    form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        >button {
            align-self: flex-start;
        }
    }
}

.store-metadata-substores {
    display: flex;
    flex-direction: column;
    gap: 20px;

    >button {
        align-self: flex-start;
    }
}

.rdt_TableHeader {
    display: none !important;
}
