.sms-statistics-table {
    .successes {
        color: #508712;
        font-weight: bold;
    }
    .errors {
        color: #cd1c1c;
        font-weight: bold;
    }
    td {
        font-size: 1rem;
    }
    tfoot span {
        display: inline-block;
        padding: 0 5px;
    }
}
