.sub-store-page {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 1200px;

    form > div {
        max-width: 100%;
    }

    table {
        margin: 20px;
    }

    .MuiPaper-root {
        width: 100%;
    }
}

.subStoreListCheckbox {
    display: flex; 
    align-items: center; 
    cursor: pointer;
}
