.schedule-page {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 1200px;

    > * {
        padding: 20px;
    }

    form > div {
        max-width: 100%;
    }

    table {
        margin: 20px;
    }
}

.schedules-standard-form {
    flex-grow: 1;
    max-width: 450px;
}

.schedules-deviation-table {
    flex-grow: 2;
    width: 600px;
}

.schedules-deviation-form {
}

.schedule-form-fields {
    margin-top: 20px;
}

.schedule-form-fields > * {
    margin-bottom: 20px !important;
}
