.pretty-print-json {
    padding: 5px;
    margin: 5px;
    min-height: 100px;
    max-height: 400px;
    overflow-y: scroll;
    font-size: 12px;
    background: rgba(0, 60, 140, .03);
    border: 1px solid rgba(0, 60, 140, .1);

    .string {
        color: green;
    }

    .number {
        color: #3a0086;
    }

    .boolean {
        color: blue;
    }

    .null {
        color: magenta;
    }

    .key {
        color: red;
    }
}
