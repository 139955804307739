.table-bordered {
    @extend .card;
}

.table-striped {
    tbody tr:nth-child(odd) {
        background: #FAFAFA;
    }
}

.table-dense-checkbox {
    tbody tr > * {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.clickable-row {
    cursor: pointer;

    &:hover td {
        background: rgba(#000, 0.025);
    }
}
