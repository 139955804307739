.rbc-event {
    padding: 0 !important;
}

.calendar-day {
    padding: 30px 0 35px;
    text-align: center;
    font-size: 16px;
    color: #000;
    background: #999;

    &.c-red {
        background: #ef5350;
    }
    &.c-green {
        background: #4caf50;
    }
    &.c-yellow {
        background: #ffac31;
    }

    &.calendar-day-notime {
        background: #999;
        * {
            opacity: 0.25;
        }
    }

    .calendar-day-orders {
        font-size: 24px;
    }

    .calendar-day-time {
        font-size: 12px;
    }
}
