.form-status {
  position: relative;
  padding: 10px;

  > * {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .form-status-error {
    color: #f44336;
  }

  .form-status-success {
    color: #43a047 !important;
  }

  .form-status-loader {
    color: #ffcb05;
  }
}
