.store-select-loader {
    display: inline-flex;
    align-self: center;

    .root {
        color: white;
        width: 16px !important;
        height: 16px !important;
    }
}
