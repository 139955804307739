.product-result-parent {
  position: relative;
}

.product-result {
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 550px;
  z-index: 1000;
  overflow-y: scroll;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .2);

  // Make scrollbar visible on iPad.
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

.product-result-match {
  outline: 1px dashed red;
  height: 38px;
  cursor: pointer;
}

.product-result-no-matches {
  padding: 25px;
}
