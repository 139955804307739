.product-categories {
    padding: 0 15px;
    margin: 0 -15px;

    .scroll {
        min-height: 855px;
        padding-right: 10px;
    }
    .items {
        margin: 0 -10px;
    }
    .item {
        cursor: pointer;
        text-align: left;
        display: block;
        padding: 0 10px 5px;
    }

    .item > div {
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        padding: 12px 10px;
    }

    .item > div:hover {
        background: #f9f9f9;
    }

    .item svg {
        float: left;
        margin-right: 5px;
        margin-top: -2px;
    }

    span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: bold;
    }
}
